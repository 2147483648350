import turfCircle from '@turf/circle'
import { polygon as turfPolygon, Position } from '@turf/helpers'
import turfIntersect from '@turf/intersect'
import { LngLatBoundsLike, LngLatLike } from 'maplibre-gl'

import { CoordinateInput, DistanceUnit, GeoShape } from '@unreserved-frontend-v2/api/generated/graphql/types'
import {
  BOUNDS_CIRCLE_CONVERSION_STEPS,
  DEFAULT_ADDRESS_SEARCH_RADIUS,
} from '@unreserved-frontend-v2/geolocation/components/map/constants'
import {
  getBoundsCoords,
  getGeoShapeCoordinatesForBounds,
  getPolygonForBounds,
  preciseLngOrLat,
} from '@unreserved-frontend-v2/geolocation/components/map/utils-lite'
import { GeoInputForUI } from '@unreserved-frontend-v2/geolocation/types'

// -------------------------------------------------------------------------------------------------------
//
// Geo
//
// -------------------------------------------------------------------------------------------------------

/**
 * Converts the passed-in query param value to a GeoInputForUI object used by the filters.
 * @param param The query param representing a geo object.
 * @returns A GeoInputForUI object.
 */
export function getGeoInputFromQueryParam(param: unknown): GeoInputForUI {
  // Exmample param: -75.67638,45.42408,25,KM,123%20Russell%20Ave%2C%20Ottawa%2C%20ON
  const values = decodeURIComponent(param as string).split(',')

  const input: GeoInputForUI = {
    longitude: Number.parseFloat(values[0]),
    latitude: Number.parseFloat(values[1]),
  }

  if (values.length > 2) {
    input.distance = {
      value: Number.parseFloat(values[2]),
      unit: values[3] === 'KM' ? DistanceUnit.Kilometers : DistanceUnit.Miles,
    }

    input.displayAddress = values.length > 4 ? values[4] : ''
  }

  // NOTE: We could have a comma in the display address, so concat anything that is left onto the display address.
  if (values.length > 5) {
    for (let i = 5; i < values.length; ++i) {
      input.displayAddress = input.displayAddress + ', ' + values[i].trim()
    }
  }

  return input
}

/**
 * Converts the passed in GeoInputForUI object and converts it to its URL representation.
 * @param input A GeoInputForUI object (used by the filters).
 * @param includePrefix True if we should include the "geo=" prefex for the query param.
 * @returns The query param (e.g. - URL representation) of the object.
 */
export function getQueryParamForGeoInput(input: unknown, includePrefix = true): string {
  const { latitude: lat, longitude: lng, distance, displayAddress } = input as GeoInputForUI
  if (!lat || !lng) {
    return ''
  }

  const { unit, value } = distance || {}

  // Example: geo=-75.67638,45.42408,25,KM,123%20Russell%20Ave%2C%20Ottawa%2C%20ON
  let param = `${lng},${lat}`
  param += unit && value ? `,${value},${unit === DistanceUnit.Kilometers ? 'KM' : 'M'}` : ''
  param += `${displayAddress ? ',' + displayAddress : ''}`

  return `${includePrefix ? 'geo=' : ''}${encodeURIComponent(param)}`
}

/**
 * Returns at LngLatLike object based on the passed-in URL geo param.
 * @param param The URL version of a geo point.
 * @returns A LngLatLike object for that point.
 */
export function getLngLatFromGeoQueryParam(param: unknown): LngLatLike | null {
  if (!param) {
    return null
  }

  // Exmample param: -78.9166941,43.8639832
  const [lng, lat] = decodeURIComponent(param as string).split(',')

  return { lng: Number.parseFloat(lng), lat: Number.parseFloat(lat) }
}

// -------------------------------------------------------------------------------------------------------
//
// Bounds
//
// -------------------------------------------------------------------------------------------------------

/**
 * Converts the passed-in query param string to a LngLatBoundsLike object.
 * @param param String representation of a LngLatBoundsLike object.
 * @returns a proper LngLatBoundsLike object
 */
export function getBoundsFromQueryParam(param: unknown): LngLatBoundsLike {
  const [swLng, swLat, neLng, neLat] = decodeURIComponent(param as string).split(',')

  return [
    { lng: Number.parseFloat(swLng), lat: Number.parseFloat(swLat) },
    { lng: Number.parseFloat(neLng), lat: Number.parseFloat(neLat) },
  ]
}

/**
 * Converts the passed in Bounds object and converts it to its URL representation.
 * @param input A Bounds object object (used by the filters).
 * @returns The query param (e.g. - URL representation) of the object.
 */
export function getQueryParamForBounds(param: unknown): string {
  if (!param) {
    return ''
  }

  // Put the coordinates in BBox order (GeoJSON spec).
  // https://datatracker.ietf.org/doc/html/rfc7946#section-5
  const [swLng, swLat, neLng, neLat] = getBoundsCoords(param as LngLatBoundsLike)
  let coord = `${preciseLngOrLat(swLng)},${preciseLngOrLat(swLat)}`
  coord += `,${preciseLngOrLat(neLng)},${preciseLngOrLat(neLat)}`

  return `bounds=${encodeURIComponent(coord)}`
}

/**
 * Returns a GeoShape input value based on the filter coordinate value.
 * @param value The filter value of for the input.
 * @returns The GeoShape input used in the queries.
 */
export function getGeoShapeInputFromFilterValue(value: unknown): GeoShape {
  return {
    coordinates: value as CoordinateInput[],
  }
}

/**
 *  When we have a geo input (e.g. - point and radius), and we are limiting the resutls to what is inside that
 *  virtual circle, we need to calculate the intersection of the circle and the map bounds. This is the polygon
 *  that will be used by the back end to return results.
 * @param bounds The current map bounds value that is set in the filters.
 * @param geo A GeoInputForUI that contains the point/radius for which to calculate a circle.
 * @returns A GeoShape input that corresponds to the point/radius of the geo input.
 */
export function getGeoShapeInputForLimitedBounds(bounds: LngLatBoundsLike, geo: GeoInputForUI): GeoShape {
  const center = [geo.longitude, geo.latitude],
    distance = geo.distance?.value || DEFAULT_ADDRESS_SEARCH_RADIUS,
    circle = turfCircle(center, distance, {
      steps: BOUNDS_CIRCLE_CONVERSION_STEPS,
      units: 'kilometers',
    })

  const boundsPoly = turfPolygon([getPolygonForBounds(bounds)])
  const intersectPoly = turfIntersect(circle, boundsPoly)
  if (intersectPoly) {
    const coordinatesToConvert = intersectPoly.geometry.coordinates[0] as Position[]

    // The back end takes care of closing the shape so don't push the last coordinate.
    const howManyCoords = coordinatesToConvert.length - 1
    const coordinates = coordinatesToConvert.reduce((acc: CoordinateInput[], coord, index) => {
      if (index < howManyCoords) {
        acc.push({ latitude: coord[1], longitude: coord[0] })
      }

      return acc
    }, [])

    return { coordinates }
  }

  // We shouldn't get here but return the default map bounds coordinates.
  return { coordinates: getGeoShapeCoordinatesForBounds(bounds as LngLatBoundsLike) }
}
